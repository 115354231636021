<template>
  <div class="download">
    <div class="process on">
      <div class="selectgroup selectdata">
        <h3>다운로드할 데이터</h3>
          <!-- <p class="q1" @click="onOpen()"><span>데이터를 선택하세요</span> <font-awesome-icon icon="fa-solid fa-caret-down" /></p> -->
          <!-- q1 클릭시 dataoptions on toggle -->
          <p class="dataoption">
            <ul>
              <li><input @click="onCheck('sns')" type="checkbox" id="sns" name="sns"><label for="sns">SNS 기록</label></li>
              <li><input @click="onCheck('edu')" type="checkbox" id="edu" name="edu"><label for="edu">교육 기록</label></li>
              <li><input @click="onCheck('ott')" type="checkbox" id="ott" name="ott"><label for="ott">OTT 기록</label></li>
              <li><input @click="onCheck('bank')" type="checkbox" id="bank" name="bank"><label for="bank">금융 기록</label></li>
              <li><input @click="onCheck('public')" type="checkbox" id="public" name="public"><label for="public">공공 기록</label></li>
              <li><input @click="onCheck('health')" type="checkbox" id="health" name="health"><label for="health">건강 기록</label></li>
            </ul>
          </p>
      </div >
      <div class="selectgroup selecttime">
        <h3>데이터 기간</h3>
        <p class="timeoption">
          <ul>
            <li><input type="radio" name="time" id="3"> <label for="3">3개월</label></li>
            <li><input type="radio" name="time" id="6"> <label for="6">6개월</label></li>
            <li><input type="radio" name="time" id="1"> <label for="1">1년</label></li>
            <li><input type="radio" name="time" id="all"> <label for="all">전체기간</label></li>
          </ul>
        </p>
      </div>
      <div class="selectgroup selectformat">
        <h3>데이터 형식</h3>
        <p class="formatoption">
          <ul>
            <li><input type="checkbox" id="html"> <label for="html">HTML</label></li>
            <li><input type="checkbox" id="csv"> <label for="csv">CSV</label> </li>
            <li><input type="checkbox" id="json"> <label for="json">JSON</label> </li>
            <li><input type="checkbox" id="xml"> <label for="xml">XML</label> </li>
          </ul>
        </p>
      </div>
      <div class="selectgroup selectdelivery">
        <h3>데이터 전송 방법</h3>
        <p class="deliveryoption">
          <ul>
            <li><input type="checkbox" id="mobile"> <label for="mobile">내 기기에 저장</label> </li>
            <li><input type="checkbox" id="email"> <label for="email">이메일</label> </li>
            <li><input type="checkbox" id="dropbox"> <label for="dropbox">드롭박스</label> </li>
            <li><input type="checkbox" id="gdrive"> <label for="gdrive">구글 드라이브</label> </li>
          </ul>
        </p>
      </div>
      <b-button @click="onSubmit()">제출하기</b-button>
    </div>
    <div class="success">
      <img src="../assets/Done-rafiki.png" alt="download center">
      <h2>제출 완료!</h2>
      <p>요청한 데이터는 24시간 안에 선택한 방법으로 전송됩니다.</p>
      <button  @click="onReset()" class="new-request">새로운 요청하기</button>
      <b-button to="/" class="to-home">메인으로 가기</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookmarksView',
  methods:{
    onStart(){
      let process = document.querySelector('.process')
      process.classList.add('on')
    },
    onSubmit(){
      let success = document.querySelector('.success')
      let process = document.querySelector('.process')
      setTimeout(function(){
        process.classList.remove('on')
        success.classList.add('on')
      }, 1000)
      
    },
    onReset(){
      let success = document.querySelector('.success')
      let process = document.querySelector('.process')
      process.classList.add('on')
      success.classList.remove('on')
      document.getElementsByTagName('input').forEach(item=>{
        item.checked = false;
      })
      document.querySelectorAll('label').forEach(item=>item.classList.remove('on'))
    },
    onCheck(id){
      document.querySelector(`#${id} + label`).classList.toggle('on')
      document.querySelector(`#${id}`).checked = true
      console.log(id)
    }
  }
}
</script>
<style scoped>
  *{margin: 0;padding: 0;}
  p{line-height: 1.2;font-size: 1rem;margin-bottom: 1rem;margin-block-start: 0;margin-block-end: 0;}
  ul{
    list-style: none;
  }
  .download{background-color: #fcfcfc;width: 100%;margin: auto;max-width: 1080px;position: relative;}
  .process{display: none;}
  .process.on{background: #fcfcfc;width: 90%;margin: auto;margin-top: 20px;margin-bottom: 20px;transition: 0.5s;display: block;}
/* 공통 */
.process .selectgroup{margin-bottom: 30px;}
.process .selectgroup h3{
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 10px;
}
.process ul{border: 1px solid #dcdcdc;box-sizing: border-box;padding: 10px 20px;border-radius: 20px;}
.process ul li{width: 50%;display: flex;}
.process ul li input{}
.process ul li label{width: 100%;display: inline-block;text-align: left;padding: 5px 10px;}

  /* 데이터 선택 */
  .process .selectdata{}
  .process .selectdata h3{}
  .process .selectdata p.dataoption{}
  .process .selectdata p.dataoption ul{margin: auto;display: flex;justify-content: space-around;flex-wrap: wrap;border: none;padding: 0;border-radius: none;}
  .process .selectdata p.dataoption ul li{margin: auto;margin-bottom: 10px;position: relative;max-width: 360px;position: relative;width: 45%;}
  .process .selectdata p.dataoption ul li label{width: 100%;padding: 10px 20px;display: block;max-width: 360px;background: transparent;border-radius: 5px;background: #efefef; border: 1px solid #e0e0e0;transition: 0.3s;margin: auto;color: #666; font-weight: 600;text-align: center;
    ;}
  .process .selectdata p.dataoption ul li input[type=checkbox]:checked + label.on{background-color: #5ebdeb;border-color: #5ebdeb;color: #fff}
  .process .selectdata p.dataoption ul li input{visibility: hidden;width: 0;height: 0;opacity: 0;z-index: -100;position: absolute;right: -999999px;}
  /* .process .selectdata p.dataoption ul li input[type=”checkbox’]:checked + label::before{content:””;position: absolute;top: 0;left: 0;width: 100%;height: 50px;background: #000;display: block;} */
  /* 데이터기간 */
  .process .selecttime{}
  .process .selecttime h3{}
  .process .selecttime p.timeoption{}
  .process .selectformat{}
  .process .selectformat h3{}
  .process .selectformat p.formatoption{}
  .process .selectdelivery{}
  .process .selectdelivery h3{}
  .process button{background: #5ebdeb;padding: 7px;width: 150px;border: none;}
  .process button:focus, .process button:active{border: none;}
  .success{display: none;width: 100%;margin: auto;box-sizing: border-box;}
  .success.on{position: absolute;top: 0;left: 0;background: #fcfcfc;padding-top: 0px;display: block;}
  .success h2{font-size: 24px;}
  .success img{width: 350px;height: 350px;}
  .success p{padding: 10px;word-break: keep-all;font-size: 16px;}
  .success .new-request{margin-top: 20px;width: 200px;margin-bottom: 10px;padding: 5px 0;background: #5EBDEB;border: none;border-radius: 5px;color: #fff;font-weight: 600;}
  .success .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{margin-top: 15px ;display: block;width: 200px;margin: auto;padding: 5px 0;background: #999;border-radius: 5px;color: #fff;font-weight: 600;border: none;}


  /* img{width: 100%;margin-bottom: 0rem;}
  p{line-height: 1.2;font-size: 1rem;margin-bottom: 1rem;margin-block-start: 0;margin-block-end: 0;}
  ul{
    list-style: none;
    margin: 0;padding: 0;
  }
  .process{
    background: #fcfcfc;width: 100%;transition: 0.5s;padding: 30px 20px;;z-index: 10;overflow: scroll;
  }
  .process h3{font-size: 1.2rem;text-align: left;margin-bottom: 20px;}
  /* .process .q1{padding: 10px;margin-top: 20px;width: 100%;z-index: 1;background: #dcdcdc;border-radius: 20px;} */
  /* .process .dataoptions{padding: 0;width: 100%;}
  .process ul{width: 100%;text-align: left;padding: 10px 20px 0 20px;display: flex;flex-wrap: wrap;border: 1px solid #dcdcdc;border-radius: 10px;align-content: center;}
  .process li{width: 50%;margin-bottom: 15px;}
  .process .dataoptions ul{display: block;border: none;padding: 0;}
  .process .dataoptions li{width: 100%;margin-bottom: 3px;display: flex;justify-content: space-between;padding: 5px 10px;box-sizing: border-box;border: 1px solid #dcdcdc;border-radius: 10px;}
  input{margin-right: 10px;}
  .process button{margin-top: 10px;}

  .success{display: none;width: 90%;margin: auto;padding-top: 60px;box-sizing: border-box;padding-top: 100px;}
  .success.on{position: absolute;top: 0;left: 0;background: #fff;width: 100%;height: 100%;display: block;}
  .success button{margin-top: 15px;} */
</style>
